import nav from "./components/nav";
import smoothScroll from "./components/smoothScroll";

nav();
smoothScroll();

if (document.querySelector(".js-notification")) {
  import("./components/notification").then((init) => init.default());
}

if (document.querySelector(".js-search")) {
  import("./components/search").then((init) => init.default());
}

if (document.querySelector(".js-tabs")) {
  import("./components/tabs").then((init) => init.default());
}

if (document.querySelector(".js-accordion")) {
  import("./components/accordion").then((init) => init.default());
}

if (document.querySelector(".js-carousel")) {
  import("./components/carousel").then((init) => init.default());
}

if (document.querySelector(".js-grid-carousel")) {
  import("./components/gridCarousel").then((init) => init.default());
}

if (document.querySelector(".js-marquee")) {
  import("./components/marquee").then((init) => init.default());
}

if (document.querySelector(".js-gallery")) {
  import("./components/gallery").then((init) => init.default());
}

if (document.querySelector("[data-aos]")) {
  import("./components/aos").then((module) => module.default.init());
}

if (document.querySelector(".js-toggle-class")) {
  import("./components/toggleClass").then((init) => init.default());
}

if (document.querySelector(".js-numeric-animation")) {
  import("./components/numericAnimation").then((init) => init.default());
}

if (document.querySelector(".js-table-of-contents")) {
  import("./components/tableOfContents").then((init) => init.default());
}

if (document.querySelector(".js-share")) {
  import("./components/share").then((init) => init.default());
}

if (document.querySelector(".js-language-picker-list")) {
  import("./components/languagePicker").then((init) => init.default());
}

if (document.querySelector(".js-reading-progress")) {
  import("./components/readingProgress").then((init) => init.default());
}

if (document.querySelector(".js-secondary-content-reveal")) {
  import("./components/secondaryContentReveal").then((init) => init.default());
}

if (document.querySelector(".js-parallax")) {
  import("./components/parallax").then((init) => init.default());
}

if (document.querySelector(".js-filterable-feed")) {
  import("./components/filterableFeed").then((init) => init.default());
}

if (document.querySelector(".js-rolodex")) {
  import("./components/rolodex").then((init) => init.default());
}

if (document.querySelector(".js-add-wrapper")) {
  import("./components/addWrapper").then((init) => init.default());
}

if (document.querySelector("iframe")) {
  import("./components/iframeResize").then((init) => init.default());
}

if (document.querySelector(".js-navigation-dropdown")) {
  import("./components/navigationDropdown").then((init) => init.default());
}

if (document.querySelector(".js-scroll-top")) {
  import("./components/scrollTop").then((init) => init.default());
}

if (document.querySelector(".js-set-parent-height")) {
  import("./components/setParentHeight").then((init) => init.default());
}
