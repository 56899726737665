export const CSS = {
  active: "is-active",
  collapsible: "collapsible",
  collapsed: "is-collapsed",
  empty: "is-empty",
  expanded: "is-expanded",
  hideContentMeta: "hide-content-meta",
  loading: "is-loading",
  open: "is-open",
  overflowHidden: "overflow--hidden",
  overflowHiddenForNav: "overflow--hidden-for-nav",
  visible: "is-visible",
};
